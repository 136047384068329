import React from 'react';
import './Dialog.css';
import closeIcon from '../../images/close.png';

const Dialog = ({ title, text, isOpen, onOk, onCancel }) => {
    const handleClose = () => {
        onCancel();
    };

    const handleOk = () => {
        onOk();
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div>
            {isOpen && (
                <div className="dialog-background">
                    <div className="dialog-container">
                        <div className="dialog-header">
                            <h3 className="dialog-title">{title}</h3>
                            <button className="button dialog-close-button" onClick={handleClose}>
                                <img src={closeIcon} alt="" width={20} />
                            </button>
                        </div>
                        <div className="dialog-body">
                            <p className="dialog-text">{text}</p>
                        </div>
                        <div className="dialog-footer">
                            <button className="button dialog-button" onClick={handleOk}>
                                OK
                            </button>
                            <button className="button dialog-button cancel" onClick={handleCancel}>
                                Отмена
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dialog;