import React, { useEffect, useRef } from 'react';
import { host } from '../../App';
import './Icon.css';
import iconPrimary from '../../images/icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUploadFile, setElement } from '../Element/element.slice';
import loading from '../../images/loading.gif';
import ImageComponent from '../ImageComponent/ImageComponent';

function Icon({ props, data, action, property, setElementState }) {
  const dispatch = useDispatch();
  const { uploadStatus, uploadResult } = useSelector(state => state.element);
  const imageElRef = useRef(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      imageElRef.current.src = reader.result;
      const formData = new FormData();
      formData.append('file', file);
      dispatch(fetchUploadFile({ token: localStorage.getItem('token'), body: formData }));
    };
    reader.readAsDataURL(file);
  }
  useEffect(() => {
    if (uploadStatus === 'success') {
      if (setElementState) {
        setElementState(property, uploadResult.url);
        return;
      }
      dispatch(setElement({ property, value: uploadResult.url }));
      if (imageElRef?.current !== undefined && imageElRef?.current !== null) {
        imageElRef.current.src = host.url + '/api/' + data;
      }
    }
  }, [uploadStatus, data, property, uploadResult, dispatch, setElementState]);
  return (
    <div>
      {
        action === 'view' &&
        <div className='icon-view'>
          <p className="label-view">{props.label}</p>
          <ImageComponent src={data ? host.url + '/api/' + data : iconPrimary} width={60} height={60} alt='' />
        </div>
      }
      {
        ((uploadStatus === 'success' || uploadStatus === null) && (action === 'edit' || action === 'add' || action === 'copy')) &&
        <div className='icon-view'>
          <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <img ref={imageElRef} src={data ? host.url + '/api/' + data : iconPrimary} width={60} height={60} alt='' />
            <form onSubmit={e => e.preventDefault()} >
              <input type="file" id='iconFileSelect' style={{ display: 'none' }} accept='image/*' onChange={handleImageChange} />
            </form>
            <button className="button" onClick={e => document.getElementById('iconFileSelect').click()}>Выбрать картинку</button>
          </div>
        </div>
      }
      {
        uploadStatus === 'loading' && (action === 'edit' || action === 'add' || action === 'copy') &&
        <div className='icon-view'>
          <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', minWidth: '60px' }}>
            <img src={loading} width={40} height={40} alt='' />
          </div>
        </div>
      }
      {
        uploadStatus === 'error' && (action === 'edit' || action === 'add' || action === 'copy') &&
        <div className='icon-view'>
          <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', minWidth: '60px' }}>
            <p className='error'>Не удалось загрузить картинку</p>
            <button className="button" onClick={e => document.getElementById('iconFileSelect').click()}>Выбрать картинку</button>
          </div>
        </div>
      }
    </div>
  )
}

export default Icon