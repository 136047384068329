import { configureStore } from "@reduxjs/toolkit";
// import slideshow from "./components/Slideshow/slideshow.slice";
// import itemsSlice from "./components/Items/items.slice";
// import cartSlice from "./components/Cart/cart.slice";
import layoutSlice from "./components/Layout/layout.slice";
import listSlice from "./components/List/list.slice";
import elementSlice from "./components/Element/element.slice";
// import modalCartItemSlice from "./components/ModalCartItem/modalCartItem.slice";
// import catalogSlice from "./components/Catalog/catalog.slice";
import userSlice from "./components/User/user.slice";
// import badgeButtonSlice from "./components/BadgeButton/BadgeButton.slice";
// import ordersSlice from "./components/Orders/orders.slice";
// import orderSlice from "./components/Order/order.slice";
// import shopsSlice from "./components/Shops/shops.slice";
import shopSlice from "./components/Shop/shop.slice";
// import clipsSlice from "./components/Clips/clips.slice";
// import searchSlice from "./components/Search/search.slice";


export const store = configureStore({
    reducer: {
        // slideshow: slideshow,
        // items: itemsSlice,
        // cart: cartSlice,
        pages: layoutSlice,
        list: listSlice,
        element: elementSlice,
        // modalCartItem: modalCartItemSlice,
        // catalog: catalogSlice,
        user: userSlice,
        // badgeButton: badgeButtonSlice,
        // orders: ordersSlice,
        // order: orderSlice,
        // shops: shopsSlice,
        shop: shopSlice,
        // clips: clipsSlice,
        // search: searchSlice,
    },
    devTools: true,
});

