import React from 'react';
import Text from '../Text/Text';
import NumberConponent from '../Number/Number';
import Icon from '../Icon/Icon';
import JSONCOMPONENT from '../JSON/JSON';
import Image from '../Image/Image';
import Button from '../Button/Button';
import Collection from '../Collection/Collection';
import Select from '../Select/Select';
import Check from '../Check/Check';
import Phone from '../Phone/Phone';

function Component({ props, data, action, property, setElementState }) {
    let component;
    switch (props?.type) {
        case 'text': component = <Text property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'number': component = <NumberConponent property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'icon': component = <Icon property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'json': component = <JSONCOMPONENT property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'image': component = <Image property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'button': component = <Button property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'collection': component = <Collection property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'select': component = <Select property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'check': component = <Check property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        case 'phone': component = <Phone property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
        default: component = <Text property={property} props={props} data={data} action={action} setElementState={setElementState} />; break;
    }

    return (
        <>
            {
                component
            }
        </>
    )
}

export default Component