import React from 'react';
import User from '../User/User';
import Shop from '../Shop/Shop';

function UniquePage({ component }) {
    let element;
    switch (component) {
        case 'user':
            element = <User />;
            break;
        case 'shop':
            element = <Shop />;
            break;
        default:
            break;
    }
    return (
        <div>
            {
                element
            }
        </div>
    )
}

export default UniquePage