import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchShops } from './shop.slice';
import { useLocation } from 'react-router-dom';
import './Shop.css';
import Items from '../Items/Items';
import ImageComponent from '../ImageComponent/ImageComponent';
import addIcon from '../../images/modal-plus.png';
import shopEmpty from '../../images/shop-gray.png';
import Component from '../Component/Component';

function Shop() {
    const dispatch = useDispatch();
    const { shop, shops, status } = useSelector((state) => state.shop);
    const token = localStorage.getItem('token');
    useEffect(() => {
        dispatch(fetchShops(token));
    }, [dispatch, token]);
    const [shopEdit, setShopEdit] = useState(shop);
    const [shopAction, setShopAction] = useState('view');
    console.log(shopEdit);
    return (
        <div>
            {
                status === 'loading' &&
                <div className='shop-page-container'>
                    loading...
                </div>
            }
            {
                status === 'error' &&
                <div className='error'>
                    Произошла ошибка во время загрузки страницы, пожалуйста, попробуйте обновить страницу
                </div>
            }
            {
                status === 'success' &&
                <div className='shop-page-container'>
                    {
                        shopEdit !== undefined &&
                        <div>
                            <div>
                                {
                                    <Component property={'title'} props={{ type: 'text', label: 'Название магазина', required: true }} />
                                }
                            </div>
                        </div>
                    }
                    {
                        shops.length === 0 && shopEdit === undefined &&
                        <div className='flex-column align-center'>
                            <ImageComponent src={shopEmpty} className='flex-center' width={96} />
                            <p className='description flex-center'>Вы ещё не открыли магазин</p>
                        </div>
                    }
                    {
                        shops.length > 0 && shopEdit === undefined &&
                        <div>
                            {
                                shops.map((shop, index) => {
                                    return (
                                        <div className='shop-item' key={index}>
                                            <p className=''>{shop.title}</p>
                                            <p>{shop.description}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        shopEdit === undefined &&
                        <div className='full-width flex-center'>
                            <button className='button background-color-light' onClick={() => { setShopAction('add'); setShopEdit({}) }}>
                                <ImageComponent src={addIcon} width={24} />
                                <p className='color-primary small-left-margin'>Добавить магазин</p>
                            </button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Shop