import React, { useState } from 'react';
import './ElementWindow.css';
import '../Element/Element.css';
import Component from '../Component/Component';
import { actionLabel } from '../Element/element.slice';

function ElementWindow({ action, element, onOk, onCancel, props, elementWindowAction }) {
    const [isComplated, setIsComplated] = useState(true);
    const [elementState, setElementToState] = useState(elementWindowAction === 'add' ? {} : element ?? {});
    const setElementState = (property, data) => {
        setElementToState({ ...elementState, [property]: data });
    }    
    const submit = () => {
        let isComplate = true;
        Object.entries(props?.elements).forEach(([key, value]) => {
            if (value.required === true && (elementState?.[key] === undefined || elementState?.[key].toString().trim() === '')) {
                isComplate = false;
            }
        })
        if (!isComplate) {
            setIsComplated(isComplate);
            setTimeout(() => {
                setIsComplated(true);
            }, 5000);
            return;
        }
        onOk(elementState);
    };

    return (
        <div className="element-window">
            <div>
                <div className="element-window-header bottom-border">
                    <h3 className="element-title">{actionLabel(elementWindowAction ?? action)} {props?.label ? ((elementWindowAction ?? action) !== 'view' ? ' - ' : '') + props?.label : ''}</h3>
                    {
                        (elementWindowAction ?? action) === 'view' && <button onClick={onCancel} className='button form-button cancel'>Закрыть</button>
                    }
                </div>
                {
                    Object.entries(props?.elements).map(([component, value], index) => {
                        try {
                            if (value.filterItem && !value.filterValues.includes((elementState[value.filterItem].value ?? elementState[value.filterItem]))) {
                                return null;
                            }
                            return (
                                <div key={index} className="component">
                                    <Component property={component} props={value} data={elementState?.[component]} action={action} setElementState={setElementState} />
                                </div>
                            )
                        } catch {
                            return null;
                        }
                    })
                }
                {
                    (((elementWindowAction ?? action) === 'add' || (elementWindowAction ?? action) === 'copy' || (elementWindowAction ?? action) === 'edit') && !isComplated) &&
                    <p className="error loading">Некоторые обязательные поля (*) не заполнены, пожалуйста заполните их и попробуйте ещё раз</p>
                }
                {
                    ((elementWindowAction ?? action) === 'add' || (elementWindowAction ?? action) === 'copy') &&
                    <div className="component flex-center">
                        <button onClick={onCancel} className='button form-button cancel'>Отмена</button>
                        <button onClick={submit} className='button form-button accept'>Добавить</button>
                    </div>
                }
                {
                    ((elementWindowAction ?? action) === 'edit') &&
                    <div className="component flex-center">
                        <button onClick={onCancel} className='button form-button cancel'>Отмена</button>
                        <button onClick={submit} className='button form-button accept'>Сохранить</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default ElementWindow