import { createSlice } from "@reduxjs/toolkit";
export const handleButtonClick = (evt, unit, operation, item) => {
    evt.target.focus();
    return modalAddMinus(unit, operation, item);
};

export const handleInputChange = (evt, unit, item) => {    
    if (evt.key === '-' | evt.key === 'ArrowLeft') {
        return modalAddMinus(unit, 'minus', item);
    } else if (evt.key === '+' | evt.key === 'ArrowRight') {
        return modalAddMinus(unit, 'plus', item);
    }
    return item;
};

export const modalAddMinus = (unit, operation, item) => {
    if (item?.discounts) {
        const countedItem = countItem(unit, operation, item);
        let discountPrice = countedItem.price.price;
        const primaryUnit = countedItem?.units?.find(fUnit => fUnit.id === countedItem.price.unit);
        countedItem?.discounts?.filter(discount => discount.count <= Math.round(primaryUnit?.count)).sort((a, b) => b.price - a.price).forEach(discount => {
            discountPrice = discount.price;
        });
        return {
            ...countedItem,
            price: {
                ...countedItem.price,
                price: discountPrice,
            }
        }
    }

    return countItem(unit, operation, item);
};

const countItem = (unit, operation, item) => {
    const returnItem = { ...item };

    if (returnItem?.units === undefined) return;

    const baseUnit = returnItem.units.find(unitFind => unitFind.id === returnItem.price.unit);
    let baseCoefficient = baseUnit.count / returnItem.price.minCount;

    if (unit.count / baseCoefficient < returnItem.price.minCount) {
        const countTrunc = Math.trunc(unit.count);
        const newCount = countTrunc + (operation === 'minus' ? countTrunc === unit.count ? -1 : 0 : 1);

        if (newCount <= 0) {
            return {
                ...returnItem,
                units: returnItem.units.map(unitMap => {
                    return {
                        ...unitMap,
                        count: Number(unitMap.count / baseCoefficient),
                    };
                }),
            };
        }

        return {
            ...returnItem,
            units: returnItem.units.map(unitMap => {
                return {
                    ...unitMap,
                    count: Number(unitMap.count * newCount / unit.count),
                };
            }),
        };
    }

    if (baseCoefficient - 1 <= 0 & operation === 'minus') {
        return returnItem;
    }

    return {
        ...returnItem,
        units: returnItem.units.map(unitMap => {
            return {
                ...unitMap,
                count: Number(unitMap.count * (baseCoefficient + (operation === 'plus' ? 1 : -1)) / baseCoefficient),
            };
        }),
    };
};

const modalCartItemSlice = createSlice({
    name: 'modalCartItem',
    initialState: {
        
    },
    reducers: {
        setItem: (state, action) => {
            state.item = action.payload;
        },
    },
});
export default modalCartItemSlice.reducer;