import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchCheckout = createAsyncThunk("cart/fetchCheckout", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(host.url + '/api/orders', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(parameters),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const splitValue = (value) => {
    return (Math.round(value * 100) / 100)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export const calculatePrice = (item, unit = null, withoutDescription = false, discount = false) => {
    if (Array.isArray(item)) {
        return splitValue(item.reduce((prev, curr) => prev + Number(calculatePrice(curr).slice(0, -2).split(' ').join('')), 0)) + ' ' + item[0].price.currency;
    }

    const returnItem = { ...item };
    const primaryUnit = returnItem?.units?.find(fUnit => fUnit.id === returnItem.price.unit);
    const itemPrice = returnItem?.price?.price;

    if (!unit) {
        return splitValue(itemPrice * primaryUnit?.count) + ' ' + returnItem?.price?.currency;
    }

    if (discount) {
        let discountPrice = item.price.price;
        const primaryUnit = item?.units?.find(fUnit => fUnit.id === item.price.unit);
        item?.discounts?.filter(discount => discount.count <= Math.round(primaryUnit?.count / unit?.count)).sort((a, b) => b.price - a.price).forEach(discount => {
            discountPrice = discount.price;
        });
        return splitValue(Math.round(Number(primaryUnit?.count * discountPrice / unit.count))) + ' ' + returnItem.price.currency;
    }

    if (withoutDescription) {
        return splitValue(Math.round(Number(primaryUnit?.count * itemPrice / unit.count))) + ' ' + returnItem.price.currency;
    }

    return splitValue(Math.round(unit.count * 100) / 100) + ' ' + unit.unit + '  по ' + splitValue(Math.round(Number(primaryUnit?.count * itemPrice / unit.count))) + ' ' + returnItem.price.currency + ' = ' + splitValue(Math.round(Number(unit.count * primaryUnit?.count * itemPrice / unit.count))) + ' ' + returnItem.price.currency;
}

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        items: localStorage.getItem('cart') === undefined ? [] : JSON.parse(localStorage.getItem('cart')) || [],
        itemState: null,
        status: null,
        error: null,
    },
    reducers: {
        addItem: (state, action) => {
            state.items.push({ ...action.payload, cartItemId: Date.now() });
            const items = state.items.map(item => { return { ...item } });
            localStorage.setItem('cart', JSON.stringify(items));
        },

        removeItem: (state, action) => {
            state.items = state.items.filter((item) => item.cartItemId !== action.payload);
            const items = state.items.map(item => { return { ...item } });
            localStorage.setItem('cart', JSON.stringify(items));
        },

        setItem: (state, action) => {
            state.itemState = action.payload;
        },

        saveItem(state, { payload: sItem }) {
            state.items = state.items.map(item => {
                if (item.cartItemId !== sItem.cartItemId) {
                    return item;
                }

                return {
                    ...sItem,
                };
            });
            const items = state.items.map(item => { return { ...item } });
            localStorage.setItem('cart', JSON.stringify(items));
        },

        setStatus(state, { payload: status }) {
            state.status = status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCheckout.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchCheckout.fulfilled, (state, action) => {
                const items = JSON.parse(localStorage.getItem('cart'));
                if (items.length > 0) {
                    state.items = [];
                    localStorage.removeItem('cart');
                    state.itemState = null;
                }

                state.status = 'link to orders';
                state.error = null;
            })
            .addCase(fetchCheckout.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload;
            });
    },
});

export const { addItem, removeItem, setItem, saveItem, checkout, setStatus } = cartSlice.actions;
export default cartSlice.reducer;