import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchItems = createAsyncThunk("items/fetchItems", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/items`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const itemsSlice = createSlice({
    name: "items",
    initialState: {
        items: [],
        itemState: null,
        status: null,
        error: null,
    },
    reducers: {
        addItems: (state, action) => {
            state.push(action.payload);
        },
        setItem(state, action) {
            state.itemState = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItems.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.items = action.payload;
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchItems.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
    },
});

export const { addItems, setItem } = itemsSlice.actions;

export default itemsSlice.reducer;