import React, { useEffect, useRef, useState } from 'react';
import './Collection.css';
import addIcon from '../../images/modal-plus.png';
import ElementWindow from '../ElementWindow/ElementWindow';
import { useDispatch, useSelector } from 'react-redux';
import { setElement } from '../Element/element.slice';
import Component from '../Component/Component';
import Context from '../Context/Context';

function Collection({ data, props, action, property, setElementState }) {
    data = data !== undefined ? data.map((record, index) => record.id === undefined ? { ...record, id: index } : record) : undefined;
    const [isElementShow, setIsElementShow] = useState(false);
    const [elementWindowAction, setElementWindowAction] = useState(action);
    const [elementWindowData, setElementWindowData] = useState();
    const { page } = useSelector(state => state.element);
    const dispatch = useDispatch();
    const submit = (element) => {
        setIsElementShow(false);
        if (setElementState) {
            setElementState(property, element);
            return;
        }
        let dataResult = data === undefined ? [] : data;
        if (element?.id === undefined) {
            dataResult = [...dataResult, { ...element, id: Math.random().toString().substring(2) }];
        } else {
            dataResult = dataResult.map(item => {
                if (item.id !== element.id) {
                    return item;
                }
                return element;
            });
        }
        dispatch(setElement({ property, value: dataResult }));
    };

    const handleOutsideClick = e => {
        if (Array.from(e.target.parentElement.classList).includes('context-menu')) {
            setTimeout(() => {
                setContextStyles({ display: 'none' });
            }, 200);
            return;
        }
        setContextStyles({ display: 'none' });
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        }
    });

    useEffect(() => {
        if (isElementShow) {
            document.getElementsByTagName('main')[0].scrollTop = 0;
        }
    }, [isElementShow]);

    const [contextStyles, setContextStyles] = useState({
        display: 'none',
    });


    const collectionContainerRef = useRef();
    const handleContextMenu = (e, item) => {
        setElementWindowData(item);
        setTimeout(() => {
            const clientX = e.clientX - collectionContainerRef.current.getBoundingClientRect().left;
            const clientY = e.clientY + document.getElementsByTagName('main')[0].scrollTop;
            setContextStyles({
                display: 'block',
                top: document.body.clientHeight - e.clientY > 100 ? clientY : 'auto',
                bottom: document.body.clientHeight - e.clientY > 100 ? 'auto' : '10px',
                left: collectionContainerRef.current.getBoundingClientRect().width - clientX > 100 ? clientX : 'auto',
                right: collectionContainerRef.current.getBoundingClientRect().width - clientX > 100 ? 'auto' : '10px',
            });
        }, 100);
    }

    const contextActions = {
        edit: () => { page.accesses.canEdit && setElementWindowAction('edit'); setIsElementShow(true); },
        delete: () => { (page.accesses.canEdit === true && elementWindowData?.id !== undefined) && dispatch(setElement({ property, value: data?.filter(item => item?.id !== elementWindowData?.id) })); },
        copy: () => { page.accesses.canEdit && setElementWindowAction('copy'); setIsElementShow(true); setElementWindowData({ ...elementWindowData, id: undefined }) },
    }

    return (
        <div ref={collectionContainerRef}>
            <div className='collection'>
                <div className='flex-space-between full-width'>
                    <p className="label-view"><span className='required'>{(props.required && action !== 'view') ? '*' : ''}</span>{props.label}</p>
                    {
                        (action !== 'view') &&
                        <button className='button list-add' onClick={() => { setIsElementShow(true); setElementWindowAction('add') }}>
                            <img src={addIcon} alt="" width={20} />
                            Добавить
                        </button>
                    }
                </div>
                <div className='collection-body'>
                    {
                        (data !== undefined && data.length > 0) &&
                        data.filter(item => item.id !== undefined).map((item, index) =>
                            <div key={index} tabIndex={0} className='collection-item-container flex-column' onClick={() => { setIsElementShow(true); setElementWindowAction(action === 'view' ? 'view' : 'edit'); setElementWindowData(item) }} onContextMenu={e => handleContextMenu(e, item)}>
                                <div className="min-height full-width">
                                    <Component props={props?.elements?.[props?.listTitle]} data={item?.[props?.listTitle]} action='view' property={props?.listTitle} />
                                </div>
                                {
                                    props?.listSubtitles?.map((subtitle, index) =>
                                        <div key={index} className='min-height full-width'>
                                            <Component props={props.elements[subtitle]} data={item[subtitle]} action='view' property={subtitle} />
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        (data === undefined || data.filter(item => item.id !== undefined).length === 0) &&
                        <p className='description center-text'>Пустая коллекция</p>
                    }
                </div>
                {
                    isElementShow &&
                    <ElementWindow onOk={(element) => submit(element)} onCancel={() => setIsElementShow(false)} element={elementWindowData} action={action} elementWindowAction={elementWindowAction} property={property} props={props} />
                }
            </div>
            {
                action !== 'view' &&
                <Context target={contextActions} style={contextStyles} elements={[{ label: 'Скопировать', disabled: !page?.accesses?.canAdd, action: 'copy' }, { label: 'Редактировать', disabled: !(page?.accesses?.canEdit && elementWindowAction?.canEdit !== false), action: 'edit' }, { label: 'Удалить', disabled: !(page?.accesses?.canEdit && elementWindowAction?.canEdit !== false), action: 'delete' }]} />
            }
        </div>
    )
}

export default Collection