import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import List from './components/List/List';
import Element from './components/Element/Element';
import NotFound from './components/NotFound/NotFound';
import UniquePage from './components/UniquePage/UniquePage';

import Layout from './components/Layout/Layout';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function App() {
  const { pages, status } = useSelector(state => state.pages);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (status === 'success' && location.pathname === '/') {
      navigate('/' + pages[0].collection);
    }
  }, [status, navigate, pages, location.pathname]);

  return (
    <div className="App" onContextMenu={(e) => { if (e?.target?.type !== 'text' && e?.target?.type !== 'textarea') { e.preventDefault() }}}>
      <Routes>
        <Route path='/' element={<Layout />}>
          {
            status === 'success' &&
            pages.map((page, pIndex) => {
              switch (page.type.value) {
                case 'list':
                  return (
                    <Route key={pIndex} path={'/' + page.collection} element={<List page={page} />} />
                  );
                case 'unique':
                  return (
                    <Route key={pIndex} path={'/' + page.collection} element={<UniquePage component={page.component} />} />
                  );
                default:
                  return (
                    <Route key={pIndex} path={'/' + page.collection} element={<List page={page} />} />
                  );
              }

            })
          }
          <Route path='/edit/*' element={<Element action='edit' />} />
          <Route path='/add/*' element={<Element action='add' />} />
          <Route path='/view/*' element={<Element action='view' />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export const host = {
  url: "https://seller.zardolu.ru",
};
export default App;
