import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchElement = createAsyncThunk("element/fetchElement", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchDeleteElement = createAsyncThunk("element/fetchDeleteElement", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`, {
            method: "DELETE",
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchSubmitElement = createAsyncThunk("element/fetchSubmitElement", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters.path}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(parameters.body),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUploadFile = createAsyncThunk("element/fetchUploadFile", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/upload/file/${parameters.token}`, {
            method: "POST",
            body: parameters.body,
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const actionLabel = (action, pathnamePartsLength = 3) => {
    let actionLabel = '';
    switch (action !== 'add' ? action : pathnamePartsLength === 3 ? 'add' : 'copy') {
        case 'add': actionLabel = 'Добавление'; break;
        case 'copy': actionLabel = 'Копирование'; break;
        case 'edit': actionLabel = 'Редактирование'; break;
        default: break;
    }
    return actionLabel;
};

const elementSlice = createSlice({
    name: "element",
    initialState: {
        element: {},
        page: {},
        isComplated: true,
        uploadStatus: null,
        uploadResult: null,
        status: null,
        error: null,
    },
    reducers: {
        setElementStatus(state, action) {
            state.status = action.payload;
        },
        setElement(state, action) {
            const { property, value } = action.payload;
            state.element[property] = value;
        },
        setIsComplated(state, action) {
            state.isComplated = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchElement.pending, (state, action) => {
                state.status = "loading";
                state.uploadResult = null;
                state.uploadStatus = null;
                state.isComplated = true;
                state.error = null;
            });
        builder
            .addCase(fetchElement.fulfilled, (state, action) => {
                state.element = Array.isArray(action.payload.data) ? {} : action.payload.data;
                state.page = action.payload.page;
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchElement.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
        builder
            .addCase(fetchDeleteElement.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchDeleteElement.fulfilled, (state, action) => {
                state.element = action.payload.data;
                state.page = action.payload.page;
                state.error = null;
                state.status = "deleted";
            });
        builder
            .addCase(fetchDeleteElement.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
        builder
            .addCase(fetchSubmitElement.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchSubmitElement.fulfilled, (state, action) => {
                state.element = action.payload.data;
                state.page = action.payload.page;
                state.error = null;
                state.status = "submitted";
            });
        builder
            .addCase(fetchSubmitElement.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
        builder
            .addCase(fetchUploadFile.pending, (state, action) => {
                state.uploadStatus = "loading";
            });
        builder
            .addCase(fetchUploadFile.fulfilled, (state, action) => {
                state.uploadStatus = "success";
                state.uploadResult = action.payload;
            });
        builder
            .addCase(fetchUploadFile.rejected, (state, action) => {
                state.uploadStatus = "error";
                console.log(action.payload);
            });
    },
});

export default elementSlice.reducer;
export const { setElementStatus, setElement, setIsComplated } = elementSlice.actions;
