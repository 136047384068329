import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Menu from '../Menu/Menu';
import './Layout.css';
import logo from '../../logo-large.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPages } from './layout.slice';
import Signin from '../Signin/Signin';

function Layout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token === null) {
            navigate('/login');
        }
    }, [navigate, token]);

    useEffect(() => {
        if (token) {
            dispatch(fetchPages(token + '?justPages=true'));
        }
    }, [dispatch, token]);

    const { pages, status, error } = useSelector((state => state.pages));

    useEffect(() => {
        if (status === 'error' && error === 'Network response was not ok') {
            localStorage.removeItem('token');
            navigate('/login');
        }
    }, [navigate, status, error]);

    return (
        <>
            {
                location.pathname === '/login' &&
                <Signin />
            }

            {
                location.pathname !== '/login' &&
                <div>
                    {
                        status === 'success' &&
                        <div className='Layout-container'>
                            <aside className='aside'>
                                <Menu isLeftMenu={true} items={pages} />
                            </aside>
                            <main className='main'>
                                <Outlet />
                            </main>
                            <footer>
                                <Menu isLeftMenu={false} items={pages} />
                            </footer>
                        </div>
                    }
                    {
                        status === 'loading' &&
                        <div className='preload-container'>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 'bold' }}>
                                <img src={logo} alt="" height={100} />
                                Зардолу
                            </div>
                        </div>
                    }
                    {
                        status === 'error' &&
                        <div className='error' style={{ padding: '15px' }}>
                            Произошла ошибка при загрузке страницы, попробуйте обновить страницу если проблема повторится попробуйте зайти позже
                        </div>
                    }
                </div>
            }

        </>
    )
}

export default Layout