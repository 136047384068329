import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchList = createAsyncThunk("list/fetchList", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const listSlice = createSlice({
    name: "list",
    initialState: {
        items: [],
        pageData: {},
        status: null,
        error: null,
    },
    reducers: {
        setStatus: (state, { payload: status }) => {
            state.status = status;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchList.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchList.fulfilled, (state, action) => {
                state.items = action.payload.data;
                state.pageData = action.payload.page;
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchList.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
    },
});

export default listSlice.reducer;
export const { setStatus } = listSlice.actions;