import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchShops = createAsyncThunk("shop/fetchShops", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(host.url + '/api/seller/shops/' + parameters);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const shopSlice = createSlice({
    name: "shop",
    initialState: {
        shop: undefined,
        shops: [],
        status: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShops.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchShops.fulfilled, (state, action) => {
                state.shops = action.payload.data;
                state.error = null;
                state.status = "success";
            })
            .addCase(fetchShops.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
    }
});

export default shopSlice.reducer;