import React from 'react';
import './Number.css';
import { useDispatch } from 'react-redux';
import { setElement } from '../Element/element.slice';

function NumberConponent({ data, props, action, property, setElementState }) {
  const dispatch = useDispatch();
  return (
    <div>
      {
        action === 'view' &&
        <div className='number-view'>
          <p className="label-view">{props.label}</p>
          <p className="secondary-text-view">{data}</p>
        </div>
      }
      {
        (action === 'add' || action === 'edit' || action === 'copy') &&
        <div className='number-view flex-column'>
          <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
          <input type="number" value={data ?? ''} onChange={e => setElementState ? setElementState(property, (e.target.value === '' ? '' : Number(e.target.value))) : dispatch(setElement({ property, value: (e.target.value === '' ? '' : Number(e.target.value)) }))} />
        </div>
      }
    </div>
  )
}

export default NumberConponent