import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchList, setStatus } from './list.slice';
import { host } from '../../App';
import './List.css';
import addIcon from '../../images/modal-plus.png';
import { NavLink, useNavigate } from 'react-router-dom';
import Context from '../Context/Context';
import Dialog from '../Dialog/Dialog';
import searchIcon from '../../images/search.png';
import closeIcon from '../../images/close.png';
import { fetchDeleteElement, setElementStatus } from '../Element/element.slice';
import filterIcon from '../../images/filter.png';
import sortIcon from '../../images/sort.png';
import sortAsc from '../../images/sort-asc.png';
import sortDesc from '../../images/sort-desc.png';
import Modal from '../Modal/Modal';
import ImageComponent from '../ImageComponent/ImageComponent';
function List({ page }) {
    const { items, status, pageData } = useSelector(state => state.list);
    const [itemsState, setItemsToState] = useState([]);
    useEffect(() => {
        if (status === 'success' && page?.title === pageData?.title) {
            setItemsToState(items);
        }
    }, [items, status, page, pageData]);
    const elementStatus = useSelector(state => state.element.status);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            dispatch(fetchList(page.collection + '/' + token));
        } else {
            navigate('/login');
        }
    }, [dispatch, page.collection, navigate, token]);

    useEffect(() => {
        if (elementStatus === 'loading') {
            dispatch(setStatus('loading'));
        }
        if (elementStatus === 'deleted') {
            dispatch(fetchList(page.collection + '/' + token));
            dispatch(setElementStatus(null));
        }
    }, [page.collection, elementStatus, dispatch, token]);

    const [contextStyles, setContextStyles] = useState({
        display: 'none',
    });
    const listContainerRef = useRef(null);
    const [target, setTarget] = useState(undefined);
    const [element, setElement] = useState(undefined);
    const onContextMenu = (e, targetItem, item) => {
        setElement(item);
        setTarget(targetItem);
        setContextStyles({
            display: 'block',
            top: document.body.clientHeight - e.clientY > 100 ? e.clientY : 'auto',
            bottom: document.body.clientHeight - e.clientY > 100 ? 'auto' : '10px',
            left: document.body.clientWidth - e.clientX > 100 ? e.clientX : 'auto',
            right: document.body.clientWidth - e.clientX > 100 ? 'auto' : '10px',
        });
    }

    const handleOutsideClick = e => {
        if (Array.from(e.target.parentElement.classList).includes('context-menu')) {
            return;
        }
        setContextStyles({ display: 'none' });
    }
    const handleKeyDown = (e) => {
        const index = Array.from(document.getElementsByClassName('list-item')).indexOf(document.activeElement);
        if (isOpen) {
            if (e.key === 'Escape') {
                setOpen(false);
                return;
            }
            if (e.key === 'Enter') {
                setOpen(false);
                dispatch(fetchDeleteElement(page.collection + '/' + localStorage.getItem('token') + '?item=' + element?._id?.toString()));
                return;
            }
            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                if (!isOpen) {
                    return;
                }
                if (Array.from(document.getElementsByClassName('dialog-button')).indexOf(document.activeElement) === -1) {
                    document.getElementsByClassName('dialog-button')[0].focus();
                    return;
                }
                if (document.getElementsByClassName('dialog-button')[0] === document.activeElement) {
                    document.getElementsByClassName('dialog-button')[1].focus();
                    return;
                }
                document.getElementsByClassName('dialog-button')[0].focus();
            }
        }
        if (e.key === 'ArrowDown') {
            if (!document.activeElement.classList.contains('list-item')) {
                document.getElementsByClassName('list-item')[0].focus();
                setElement(itemsState[0]);
                return;
            }
            if (index < document.getElementsByClassName('list-item').length - 1) {
                document.getElementsByClassName('list-item')[index + 1].focus();
                setElement(itemsState[index + 1]);
            }
        }
        if (e.key === 'ArrowUp') {
            if (document.activeElement.classList.contains('list-item')) {
                if (index > 0) {
                    document.getElementsByClassName('list-item')[index - 1].focus();
                    setElement(itemsState[index - 1]);
                }
            }
        }
        if (e.key === 'Insert' || e.key === '+') {
            if (pageData.canAdd === true) {
                navigate('/add/' + page.collection);
            }
        }
        if (e.key === 'Delete' || e.key === '-') {
            if (element?.canDelete !== false && document.activeElement.classList.contains('list-item')) {
                setOpen(true);
            }
        }
        if (e.key === 'F2') {
            if (element?.canEdit !== false) {
                navigate('/edit/' + page.collection + '/' + element?._id?.toString());
            }
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
            document.removeEventListener('keydown', handleKeyDown);
        }
    });

    useEffect(() => {
        document.title = page?.title;
        setSort({});
        setFilter({});
        setSearchValue('');
        setShowedControl();
    }, [page?.title]);

    const [isOpen, setOpen] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState({});
    const [filter, setFilter] = useState({});
    const [activeFilter, setActiveFilter] = useState();

    const setItemsState = (props = {}) => {
        const search = props?.search ?? '';
        const sorts = props.sorts ?? {};
        const filters = props.filters ?? {};
        let itemsCopy = [...items];
        //filter
        Object.entries(filters).forEach(([key, value]) => {
            itemsCopy = itemsCopy.filter(item => item[key] === value);
        });
        //search
        const titles = itemsCopy.filter(item => item[pageData.listTitle]?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
        const subTitles = itemsCopy.filter(item => (pageData?.listSubtitle?.includes('.') ? item[pageData?.listSubtitle?.split('.')[0]]?.[pageData?.listSubtitle?.split('.')[1]] : item[pageData?.listSubtitle])?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
        const dateTimes = itemsCopy.filter(item => new Date(item[pageData.listDateTime])?.toLocaleString()?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
        const sums = itemsCopy.filter(item => item[pageData.listSum]?.toString().trim().toLowerCase().startsWith(search.trim().toLowerCase()));
        const titlesIncludes = itemsCopy.filter(item => item[pageData.listTitle]?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
        const subTitlesIncludes = itemsCopy.filter(item => (pageData?.listSubtitle?.includes('.') ? item[pageData?.listSubtitle?.split('.')[0]]?.[pageData?.listSubtitle?.split('.')[1]] : item[pageData?.listSubtitle])?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
        const dateTimesIncludes = itemsCopy.filter(item => new Date(item[pageData.listDateTime])?.toLocaleString()?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
        const sumsIncludes = itemsCopy.filter(item => item[pageData.listSum]?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()));
        itemsCopy = titles.concat(subTitles, dateTimes, sums, titlesIncludes, subTitlesIncludes, dateTimesIncludes, sumsIncludes).filter((item, index, self) => self.indexOf(item) === index);
        //sort
        Object.entries(sorts).forEach(([key, value]) => {
            itemsCopy = itemsCopy.sort((a, b) => {
                if (a[key] < b[key] || (typeof a[key] === 'string' && a[key].localeCompare(b[key]) === -1)) {
                    return value === '1' ? -1 : value === '-1' ? 1 : 0;
                }
                if (a[key] > b[key] || (typeof a[key] === 'string' && a[key].localeCompare(b[key]) === 1)) {
                    return value === '1' ? 1 : value === '-1' ? -1 : 0;
                }
                return 0;
            });
        });

        setItemsToState(itemsCopy);
    }

    const closeIconRef = useRef(null);
    const handleInputChange = (e) => {
        if (e.target.value === '') {
            closeIconRef.current.classList.add('hideSearch');
        } else {
            closeIconRef.current.classList.remove('hideSearch');
        }
        setSearchValue(e.target.value);
        setItemsState({ search: e.target.value });
    }

    const handleSearch = () => {
        setItemsState();
    }

    const handleSort = (key) => {
        setSort({ [key]: sort[key] === '1' ? '-1' : '1' });
        setItemsState({ sorts: { [key]: sort[key] === '1' ? '-1' : '1' } });
    }

    const handleFilter = (key, value) => {
        setFilter({ ...filter, [key]: value });
        setItemsState({ filters: { ...filter, [key]: value } });
    }

    const [modalClasses, setModalClasses] = useState('modal-base-container modal-hide');
    const hideModal = () => {
        setModalClasses('modal-base-container modal-hide');
    }
    const [showedControl, setShowedControl] = useState();

    const handleFilterButtonClick = (key, e) => {
        if (e?.target?.alt === 'resetFilter') {
            const clearFilter = { ...filter };
            delete clearFilter[key];
            setItemsState({ filters: clearFilter });
            setFilter(clearFilter);
            setActiveFilter();
            return;
        }
        setActiveFilter(key);
        setModalClasses('modal-base-container');
    }

    return (
        <div className='list-container' ref={listContainerRef}>
            <Modal modalClasses={modalClasses} hideModal={hideModal} style={{ width: '400px', height: '500px' }} title={'Фильтр'}>
                <div>
                    {
                        itemsState.map(item => (activeFilter === 'created' || activeFilter === 'updated') ? new Date(item[activeFilter]).toLocaleDateString() : item[activeFilter]).filter((item, index, self) => self.indexOf(item) === index).map((item, index) => {
                            return (
                                <div key={index} className='list-item fit-content-height filter-list-item' onClick={() => { handleFilter(activeFilter, item); hideModal(); }}>
                                    <span>{((activeFilter === 'created' || activeFilter === 'updated') ? (item !== undefined ? new Date(item).toLocaleDateString() : console.log(item)) : item)}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
            <div className="list-header" style={{ marginBottom: '5px' }}>
                <h3>{page?.title}</h3>
                <div className='flex-start'>
                    {
                        (pageData?.accesses?.canAdd === true && status === 'success') &&
                        <button className='button list-add' onClick={() => navigate('/add/' + page.collection)}>
                            <img src={addIcon} alt="" width={20} />
                            Добавить
                        </button>
                    }
                </div>
            </div>
            <div className='list'>
                {
                    (status === 'success' && page?.title === pageData?.title) &&
                    <div>
                        <div className="header-controls full-width">
                            <div className="controls-container flex align-center">
                                {
                                    showedControl === 'search' &&
                                    <div className='flex-start align-center full-width control-container'>
                                        <div className='list-search-input-container'>
                                            <input type="text" placeholder='Поиск' value={searchValue} onChange={handleInputChange} />
                                            <div ref={closeIconRef} tabIndex={0} className={"list-search-close-icon" + (searchValue === '' ? ' hideSearch' : '')} onClick={() => { setSearchValue(''); closeIconRef.current.classList.add('hideSearch'); setItemsState({ search: '' }) }}>
                                                <img src={closeIcon} alt="" />
                                            </div>
                                            <div className="list-search-icon" tabIndex={0} onClick={() => handleSearch()}>
                                                <img src={searchIcon} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    showedControl === 'filter' &&
                                    <div className='flex-start align-center full-width control-container'>
                                        {
                                            pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label !== undefined &&
                                            <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listTitle] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listTitle, e)}>
                                                <span>{pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label}{(filter[pageData?.listTitle] !== undefined ? ': ' + filter[pageData?.listTitle] : '')}</span>
                                                {
                                                    filter[pageData?.listTitle] !== undefined &&
                                                    <div className='filter-clear'>
                                                        <img src={closeIcon} alt="resetFilter" width={10} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label !== undefined &&
                                            <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listSubtitle] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listSubtitle, e)}>
                                                <span>{pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label}{(filter[pageData?.listSubtitle] !== undefined ? ': ' + filter[pageData?.listSubtitle] : '')}</span>
                                                {
                                                    filter[pageData?.listSubtitle] !== undefined &&
                                                    <div className='filter-clear'>
                                                        <img src={closeIcon} alt="resetFilter" width={10} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            (pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined || (pageData?.listDateTime?.split('.')?.[0]) === 'created' || (pageData?.listDateTime?.split('.')?.[0]) === 'updated') &&
                                            <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listDateTime] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listDateTime, e)}>
                                                <span>{(pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined ? pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label : (pageData?.listDateTime?.split('.')?.[0]) === 'created' ? 'Дата создания' : 'Дата обновления')}{(filter?.[pageData?.listDateTime] !== undefined ? ': ' + (((pageData?.listDateTime === 'created' || pageData?.listDateTime === 'updated') ? new Date(filter?.[pageData?.listDateTime]).toLocaleDateString() : filter?.[pageData?.listDateTime])) : '')}</span>
                                                {
                                                    filter[pageData?.listDateTime] !== undefined &&
                                                    <div className='filter-clear'>
                                                        <img src={closeIcon} alt="resetFilter" width={10} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label !== undefined &&
                                            <div className={'button color-primary background-color-light small-padding rounded control-item-button' + (filter[pageData?.listSum] !== undefined ? '  button-primary' : '')} onClick={e => handleFilterButtonClick(pageData?.listSum, e)}>
                                                <span>{pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label}{(filter[pageData?.listSum] !== undefined ? ': ' + filter[pageData?.listSum] : '')}</span>
                                                {
                                                    filter[pageData?.listSum] !== undefined &&
                                                    <div>
                                                        <img src={closeIcon} alt="resetFilter" width={10} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    showedControl === 'sort' &&
                                    <div className='flex-start align-center full-width control-container'>
                                        {
                                            pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label !== undefined &&
                                            <div className='button color-primary background-color-light small-padding rounded control-item-button' onClick={() => handleSort((pageData?.listTitle?.split('.')?.[0]))}>
                                                <span>{pageData?.components?.[(pageData?.listTitle?.split('.')?.[0])]?.label}</span>
                                                {
                                                    sort[(pageData?.listTitle?.split('.')?.[0])] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                }
                                                {
                                                    sort[(pageData?.listTitle?.split('.')?.[0])] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                }
                                            </div>
                                        }
                                        {
                                            pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label !== undefined &&
                                            <div className='button color-primary background-color-light small-padding rounded control-item-button' onClick={() => handleSort(pageData?.listSubtitle)}>
                                                <span>{pageData?.components?.[(pageData?.listSubtitle?.split('.')?.[0])]?.label}</span>
                                                {
                                                    sort[pageData?.listSubtitle] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                }
                                                {
                                                    sort[pageData?.listSubtitle] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                }
                                            </div>
                                        }
                                        {
                                            (pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined || (pageData?.listDateTime?.split('.')?.[0]) === 'created' || (pageData?.listDateTime?.split('.')?.[0]) === 'updated') &&
                                            <div className='button color-primary background-color-light small-padding rounded control-item-button' onClick={() => handleSort((pageData?.listDateTime?.split('.')?.[0]))}>
                                                <span>{(pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label !== undefined ? pageData?.components?.[(pageData?.listDateTime?.split('.')?.[0])]?.label : (pageData?.listDateTime?.split('.')?.[0]) === 'created' ? 'Дата создания' : 'Дата обновления')}</span>
                                                {
                                                    sort[(pageData?.listDateTime?.split('.')?.[0])] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                }
                                                {
                                                    sort[(pageData?.listDateTime?.split('.')?.[0])] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                }
                                            </div>
                                        }
                                        {
                                            pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label !== undefined &&
                                            <div className='button color-primary background-color-light small-padding rounded control-item-button' onClick={() => handleSort((pageData?.listSum?.split('.')?.[0]))}>
                                                <span>{pageData?.components?.[(pageData?.listSum?.split('.')?.[0])]?.label}</span>
                                                {
                                                    sort[(pageData?.listSum?.split('.')?.[0])] === '1' && <img src={sortAsc} alt="" height={20} className='small-left-margin' />
                                                }
                                                {
                                                    sort[(pageData?.listSum?.split('.')?.[0])] === '-1' && <img src={sortDesc} alt="" height={20} className='small-left-margin' />
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    showedControl !== undefined &&
                                    <button className="button color-primary small-left-margin primary-background-color-light cursor-pointer small-padding" style={{ height: 'fit-content' }} onClick={() => setShowedControl()}>
                                        <img src={closeIcon} alt="" width={15} />
                                    </button>
                                }
                            </div>

                            {
                                showedControl === undefined &&
                                <div className='list-controls flex-start'>
                                    <div className="button small-padding small-margin cursor-pointer releative" onClick={() => setShowedControl('sort')}>
                                        <img src={sortIcon} alt="" width={20} />
                                        <span className='small-left-margin color-primary'>Сортировка</span>
                                        {
                                            Object.values(sort).filter(value => value !== '0')?.length > 0 &&
                                            <span className='required push-point'>●</span>
                                        }
                                    </div>
                                    <div className="button small-padding small-margin cursor-pointer releative" onClick={() => setShowedControl('filter')}>
                                        <img src={filterIcon} alt="" width={20} />
                                        <span className='small-left-margin color-primary'>Фильтр</span>
                                        {
                                            Object.values(filter)?.length > 0 &&
                                            <span className='required push-point'>●</span>
                                        }
                                    </div>
                                    <div className="button small-padding small-margin cursor-pointer releative" onClick={() => setShowedControl('search')}>
                                        <img src={searchIcon} alt="" width={20} />
                                        <span className='small-left-margin color-primary'>Поиск</span>
                                        {
                                            searchValue?.length > 0 &&
                                            <span className='required push-point'>●</span>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            status === 'success' && page?.title === pageData?.title && itemsState.map((item, index) => {
                                const listTitle = item?.[page?.listTitle] !== undefined ? item[page.listTitle] : '';
                                const listSubtitle = page?.listSubtitle !== undefined ? page?.listSubtitle?.includes('.') ? item?.[page?.listSubtitle?.split('.')?.[0]]?.[page?.listSubtitle?.split('.')[1]] : item?.[page?.listSubtitle] : '';
                                const listDateTime = item?.[page?.listDateTime] !== undefined ? new Date(item[page.listDateTime]).toLocaleString() : '';
                                const listSum = item?.[page?.listSum] !== undefined ? item[page.listSum] : '';
                                const listSumAddition = item?.[page.listSum] ? page.listSumAddition : '';
                                return (
                                    <div style={{ position: 'relative' }} key={index}>
                                        <NavLink to={'/view/' + page.collection + '/' + item._id.toString()} className='list-item' onContextMenu={(e) => { onContextMenu(e, page.collection + '/' + item._id.toString(), item) }}>
                                            {
                                                item.icon !== undefined &&
                                                <div className="list-icon">
                                                    <ImageComponent src={host.url + '/api/' + item.icon} width={48} />
                                                </div>
                                            }
                                            <div className="list-info">
                                                <div className="list-titles">
                                                    <p className="list-title">{typeof listTitle !== 'object' ? listTitle : ''}</p>
                                                    <p className='list-subtitle'>{typeof listSubtitle !== 'object' ? listSubtitle : ''}</p>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div className="list-overalls">
                                                        <p className="list-date-time">{typeof listDateTime !== 'object' ? listDateTime : ''}</p>
                                                        <p className="list-sum">{typeof listSum !== 'object' ? listSum : ''} {typeof listSumAddition !== 'object' ? listSumAddition : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                )
                            })
                        }
                        {
                            itemsState.length === 0 &&
                            <div className='description flex-center'>Список пуст</div>
                        }
                    </div>
                }
                <Dialog
                    isOpen={isOpen}
                    title="Удаление"
                    text='Вы дейстивительно хотите удалить этот элемент?'
                    onCancel={
                        () => {
                            setOpen(false);
                        }
                    }
                    onOk={
                        () => {
                            setOpen(false);
                            dispatch(fetchDeleteElement(page.collection + '/' + localStorage.getItem('token') + '?item=' + element?._id?.toString()));
                        }
                    }
                />
                <Context setOpen={setOpen} target={target} style={contextStyles} elements={[{ label: 'Скопировать', disabled: !pageData?.accesses?.canAdd, action: 'copy' }, { label: 'Редактировать', disabled: !(pageData?.accesses?.canEdit && element?.canEdit !== false), action: 'edit' }, { label: 'Удалить', disabled: !(pageData?.accesses?.canDelete && element?.canDelete !== false), action: 'delete' }]} />
            </div>

            <div className='list'>
                {
                    (status === 'loading' || page?.title !== pageData?.title) &&
                    Array.from({ length: 10 }).map((item, index) => {
                        return (
                            <div key={index} className='list-item loading'>
                                <div className="list-icon"></div>
                                <div className="list-info loading">
                                    <div className="list-titles">
                                        <p className="list-title loading"></p>
                                        <p className='list-subtitle loading'></p>
                                    </div>
                                    <div className="list-overalls">
                                        <p className="list-date-time loading"></p>
                                        <p className="list-sum loading"></p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div >
    )
}

export default List