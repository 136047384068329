import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { actionLabel, fetchDeleteElement, fetchElement } from './element.slice';
import Component from '../Component/Component';
import './Element.css';
import menuIcon from '../../images/menu-vertical.png';
import Context from '../Context/Context';
import Dialog from '../Dialog/Dialog';

function Element({ action }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathnameParts = location.pathname.split('/');
    const pathnamePartsLength = pathnameParts.length;
    const id = pathnamePartsLength === 3 ? undefined : pathnameParts.pop();
    const pageCollection = pathnameParts.pop();
    useEffect(() => {
        dispatch(fetchElement(pageCollection + '/' + localStorage.getItem('token') + (pathnamePartsLength === 3 ? '' : '?item=' + id)));
    }, [dispatch, id, pageCollection, action, pathnamePartsLength]);

    const { element, page, status, isComplated } = useSelector(state => state.element);
    const { _id, ...addElement } = element ?? {};

    useEffect(() => {
        if (status === 'deleted') {
            navigate('/' + pageCollection);
        }
        if (status === 'submitted') {
            navigate('/view/' + pageCollection + '/' + _id?.toString());
        }
    }, [pageCollection, status, navigate, _id]);

    const [contextStyles, setContextStyles] = useState({
        display: 'none',
    });

    const handleOutsideClick = e => {
        if (Array.from(e.target.parentElement.classList).includes('context-menu')) {
            setTimeout(() => {
                setContextStyles({ display: 'none' });
            }, 200);
            return;
        }
        setContextStyles({ display: 'none' });
    }
    const handleKeyDown = e => {
        if (e.target === document.activeElement && document.activeElement.tagName === 'INPUT') {
            return;
        }
        if (e.target === document.activeElement && document.activeElement.tagName === 'TEXTAREA') {
            return;
        }
        if (e.key === 'Escape') {
            document.getElementsByClassName('cancel')[0] && document.getElementsByClassName('cancel')[0].click();
        }
        if (e.key === 'Enter' && e.ctrlKey) {
            if (action === 'view') {
                navigate('/' + pageCollection);
                return;
            }
            document.getElementsByClassName('accept')[0] && document.getElementsByClassName('accept')[0].click();
        }
        if (e.key === 'F2') {
            if (action === 'view') {
                navigate('/edit/' + pageCollection + '/' + _id?.toString());
            }
        }
        if (e.key === 'Delete' || e.key === '-') {
            if (element?.canDelete !== false) {
                setOpen(true);
            }
        }
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            if (!isOpen) {
                return;
            }
            if (Array.from(document.getElementsByClassName('dialog-button')).indexOf(document.activeElement) === -1) {
                document.getElementsByClassName('dialog-button')[0].focus();
                return;
            }
            if (document.getElementsByClassName('dialog-button')[0] === document.activeElement) {
                document.getElementsByClassName('dialog-button')[1].focus();
                return;
            }
            document.getElementsByClassName('dialog-button')[0].focus();
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
            document.removeEventListener('keydown', handleKeyDown);
        }
    });

    const handleMenuClick = e => {
        setTimeout(() => {
            setContextStyles({
                display: 'block',
                position: 'absolute',
                top: '5px',
                right: '40px',
            });
        }, 100);
    };

    let actionLabelResult = actionLabel(action, pathnamePartsLength);
    

    useEffect(() => {
        const firstPart = actionLabelResult === '' ? '' : actionLabelResult;
        const secondPart = element?.[page?.listTitle] === undefined ? page?.title ?? '' : element?.[page?.listTitle];
        document.title = firstPart.toString() + (firstPart !== '' && secondPart !== '' ? ' - ' : '') + secondPart.toString();
    }, [actionLabelResult, element, page?.listTitle, page?.title]);

    const [isOpen, setOpen] = useState(false);
    return (
        <div>
            <div className="element-container">
                {
                    status === 'success' &&
                    <div className="components">
                        <div className="element-header bottom-border">
                            <h3 className="element-title">{(actionLabelResult === '' ? '' : actionLabelResult + ' - ') + (element?.[page.listTitle] === undefined ? page.title : element?.[page.listTitle])}</h3>
                            {
                                (page?.accesses?.canAdd === true || page?.accesses?.canEdit === true || page?.accesses?.canDelete === true) &&
                                <div>
                                    <button className="button menu-vertical" onClick={handleMenuClick} style={{ display: action === 'view' ? 'flex' : 'none' }}>
                                        <img src={menuIcon} alt="" height={20} />
                                    </button>
                                    <Context setOpen={setOpen} target={page.collection + '/' + _id?.toString()} style={contextStyles} elements={[{ label: 'Скопировать', disabled: !page?.accesses?.canAdd, action: 'copy' }, { label: 'Редактировать', disabled: !(page?.accesses?.canEdit && element?.canEdit !== false), action: 'edit' }, { label: 'Удалить', disabled: !(page?.accesses?.canDelete && element?.canDelete !== false), action: 'delete' }]} />
                                </div>
                            }
                        </div>
                        {
                            Object.entries(page.components).map(([component, value], index) => {
                                try {
                                    if (value.filterItem && !value.filterValues.includes((element[value.filterItem].value ?? element[value.filterItem]))) {
                                        return null;
                                    }
                                    return (
                                        <div key={index} className="component">
                                            <Component property={component} props={value} data={element[component]} action={action !== 'add' ? action : pathnamePartsLength === 3 ? 'add' : 'copy'} />
                                        </div>
                                    )
                                } catch {
                                    return null;
                                }
                            })
                        }
                        {
                            ((action === 'add' || action === 'copy' || action === 'edit') && !isComplated) &&
                            <p className="error loading">Некоторые обязательные поля (*) не заполнены, пожалуйста заполните их и попробуйте ещё раз</p>
                        }
                        {
                            (action === 'add' || action === 'copy') &&
                            <div className="component flex-center">
                                <Component props={{ type: 'button', label: 'Отмена' }} action={'cancel'} />
                                <Component props={{ type: 'button', label: 'Добавить', path: page.collection + '/' + localStorage.getItem('token'), body: addElement }} action={'submit'} />
                            </div>
                        }
                        {
                            (action === 'edit') &&
                            <div className="component flex-center">
                                <Component props={{ type: 'button', label: 'Отмена' }} action={'cancel'} />
                                <Component props={{ type: 'button', label: 'Сохранить', path: page.collection + '/' + localStorage.getItem('token'), body: element }} action={'submit'} />
                            </div>
                        }

                        <Dialog
                            isOpen={isOpen}
                            title="Удаление"
                            text='Вы дейстивительно хотите удалить этот элемент?'
                            onCancel={
                                () => {
                                    setOpen(false);
                                }
                            }
                            onOk={
                                () => {
                                    setOpen(false);
                                    dispatch(fetchDeleteElement(page.collection + '/' + localStorage.getItem('token') + '?item=' + _id?.toString()));
                                }
                            }
                        />
                    </div>
                }

                {
                    status === 'loading' &&
                    <div className="components">
                        <div className="element-header">
                            <div className="element-title loading" style={{ height: '35px', width: '100%', display: 'flex', alignItems: 'center' }}><p style={{ height: '20px', width: '50%', margin: '5px' }} className='loading'></p></div>
                        </div>
                        {
                            Array.from({ length: 10 }).map((el, index) => {
                                return (
                                    <div key={index} style={{ height: Math.round(Math.random() * 100 + 20) + 'px', width: '100%', minWidth: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='loading'>
                                        <div className='loading' style={{ width: '90%', height: '80%', borderRadius: '20px' }}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    status === 'error' &&
                    <div className='error'>Не удалось загрузить страницу</div>
                }
            </div>
        </div>
    )
}

export default Element