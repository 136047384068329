import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchPages = createAsyncThunk("pages/fetchPages", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/pages/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const pagesSlice = createSlice({
    name: "pages",
    initialState: {
        pages: [],
        status: null,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPages.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchPages.fulfilled, (state, action) => {
                state.pages = action.payload.data;
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchPages.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
    },
});

export default pagesSlice.reducer;