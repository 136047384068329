import { React, useEffect, useRef, useState } from 'react';
import './ImageViewer.css';
import modalLeft from '../../images/modal-left.png';
import modalRight from '../../images/modal-right.png';
import play from '../../images/play.png';
import pause from '../../images/pause.png';
import loading from '../../images/loading.gif';

function ImageViewer({ images, controllers, autoSlide, dots, autoPlay }) {
    const [indexState, setIndex] = useState(0);

    const slide = (side, index = null) => {
        if (index !== null) {
            setIndex(index);
            return;
        }

        if (side === 'left') {
            setIndex(indexState - 1 >= 0 ? indexState - 1 : images.length - 1);
            return;
        }

        setIndex(indexState + 1 <= images.length - 1 ? indexState + 1 : 0);
    }

    let startX, endX, startY, endY;

    const touchStart = (evt) => {
        startX = evt.touches[0].clientX;
        startY = evt.touches[0].clientY;
    };

    const touchMove = (evt) => {
        endX = evt.touches[0].clientX;
        endY = evt.touches[0].clientY;
    };

    const touchEnd = (evt) => {
        if (startX - endX > 40 && Math.abs(startY - endY) < 20) {
            slide('right');
        }

        if (endX - startX > 40 && Math.abs(startY - endY) < 20) {
            slide('left');
        }
    };

    const [slideRight, setSlideRight] = useState(false);

    const loadingRef = useRef(null);
    useEffect(() => {
        const slideInterval = setInterval(() => {
            if (slideRight & autoSlide) {
                slide('right');
            }
        }, 1000);

        const videoReadyStateInterval = setInterval(() => {
            if (playerRef.current === null || loadingRef.current === null) {
                return;
            }

            if (playerRef.current.readyState === 4) {
                loadingRef.current.style.display = 'none';
                return;
            }

            if (playerRef.current.readyState <= 2) {
                loadingRef.current.style.display = 'block';
            }
        }, 100);

        return () => {
            clearInterval(slideInterval);
            clearInterval(videoReadyStateInterval);
        };
    });

    const mouseEnter = () => {
        setSlideRight(true);
    };

    const mouseLeave = () => {
        setSlideRight(false);
    };

    const [playing, setPlaying] = useState(null);
    const playerIconRef = useRef(null);
    const playerRef = useRef(null);

    const playPause = (player) => {
        if (playerRef.current === null) {
            return;
        }
        playerIconRef.current.style.display = 'block';
        try {
            if (playing === true || playing === null) {
                if (player !== null) {
                    player.pause()?.catch(e => e ?? console.log(e.message));
                    setPlaying(false);
                }
            } else {
                if (player !== null) {
                    player.play()?.catch(e => e ?? console.log(e.message));
                    setPlaying(true);
                    setTimeout(() => {
                        if (playerIconRef?.current?.style?.display !== undefined) {
                            playerIconRef.current.style.display = 'none';
                        }
                    }, 2000);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        try {
            if (playerRef.current !== null) {
                if (autoPlay && playing === null) {
                    playerRef.current.play()?.catch(e => e ?? console.log(e.message));
                } else if (!autoPlay) {
                    playerRef.current.pause()?.catch(e => e ?? console.log(e.message));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [playing, autoPlay]);

    return (
        <div className='image-container' onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            {
                images?.[indexState]?.type === 'video' ?
                    <div className='video-container'>
                        <img src={playing ? pause : play} alt="" className='play-pause-button' ref={playerIconRef} onClick={(evt) => playPause(playerRef.current)} />
                        <img src={loading} alt="" className='video-loading' ref={loadingRef} />
                        <video
                            ref={playerRef}
                            className='video'
                            src={images?.[indexState]?.src}
                            autoPlay={autoPlay}
                            muted
                            loop
                            onClick={(evt) => playPause(evt.target)}
                            playsInline
                            onLoadStart={() => loadingRef.current.style.display = 'block'}
                            onLoad={() => loadingRef.current.style.display = 'none'}
                        />
                    </div>
                    :
                    <img className='image' src={images?.[indexState]?.src} alt="" />
            }
            {
                (dots && images?.length > 1) &&
                <div className="dots-container">
                    {
                        images?.map((image, index) => {
                            return (
                                <span key={image.src} className={(index === indexState ? 'active-dot' : '')} onClick={() => slide(index)}>●</span>
                            );
                        })
                    }
                </div>
            }
            {
                (controllers && images?.length > 1) &&
                <div className="image-controls">
                    <button className='button' onClick={() => slide('left')}><img src={modalLeft} alt="" /></button>
                    <button className='button' onClick={() => slide('right')}><img src={modalRight} alt="" /></button>
                </div>
            }
        </div>
    )
}

export default ImageViewer