import ImageViewer from '../ImageViewer/ImageViewer';
import { calculatePrice, splitValue } from '../Cart/cart.slice';
import modalMinus from '../../images/modal-minus.png';
import modalPlus from '../../images/modal-plus.png';
import cart from '../../images/cart.png';
import { handleButtonClick, handleInputChange } from './modalCartItem.slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

let evt, unitI, operation, touching = false, start = false;
function ModalCartItem({ itemState, addToCart, setItem, submitText }) {
    const dispatch = useDispatch();

    useEffect(() => {
        const touchInterval = setInterval(() => {
            if (touching & start) {
                dispatch(setItem(handleButtonClick(evt, unitI, operation, itemState)));
            }
        }, 100);

        return () => {
            clearInterval(touchInterval);
        }
    });

    return (
        <div className="add-to-cart-container">
            <div className="item-image-container">
                <ImageViewer images={itemState?.images} controllers={true} dots={true} autoPlay={true} />
            </div>
            <div className="modal-item-container">
                <div className="modal-item-info" style={{ fontWeight: 'bold' }}>
                    <span className="modal-item-title">{itemState?.title ?? ''}</span>
                    <span className="modal-item-price">{splitValue(itemState?.price?.price)} {itemState?.price?.currency ?? '₽'} {itemState?.units?.find(unit => unit.id === itemState.price.unit).unit ? '• за 1' : ''} {itemState?.units?.find(unit => unit.id === itemState.price.unit).unit}</span>
                    {
                        itemState?.discounts?.length > 0 &&
                        <div className="catalog-item-discounts-container modal-item-discounts" style={{ margin: '15px 0 0 0' }}>
                            <p className='catalog-item-relations' style={{ fontSize: '12px', margin: '0' }}>Скидки</p>
                            {
                                itemState?.discounts?.map((discount, index) => {
                                    return <p key={index} className='catalog-item-info' style={{ fontSize: '12px', margin: '0' }}><span className='catalog-item-propertyName'>От {discount.count} {itemState.units?.find(unit => unit.id === itemState.price.unit).unit}: </span>{discount.price} {itemState.price.currency} • за 1 {itemState.units?.find(unit => unit.id === itemState.price.unit).unit}</p>
                                })
                            }

                        </div>
                    }
                </div>
                <div className="modal-item-units">
                    <p className="description">Выберите единицу измерения и количество:</p>
                    {
                        itemState?.units?.map((unit, index) => {
                            return (
                                <div key={unit.id} className="modal-unit-container">
                                    <div className="modal-count-controls">
                                        <button className="button" onTouchStart={(e) => { touching = true; setTimeout(() => { if (touching) { evt = e; operation = 'minus'; unitI = unit; start = true; } }, 500) }} onTouchEnd={() => { touching = false; start = false; }} onClick={(evt) => dispatch(setItem(handleButtonClick(evt, unit, 'minus', itemState)))}><img src={modalMinus} alt="" /></button>
                                        <div className='modal-unit-title'>
                                            <p style={{ display: 'none' }}>{unit.title}</p>
                                            <input style={{ display: 'none' }} type="number" value={Math.round(unit.count * 100) / 100} onKeyDown={(evt) => handleInputChange(evt, unit, itemState)} readOnly />
                                            <p className="modal-unit-count">{splitValue(Math.round(unit.count * 100) / 100)} {unit.unit}</p>
                                            <span className="modal-unit-info">{calculatePrice(itemState, unit)}</span>
                                        </div>
                                        <button className="button" onTouchStart={(e) => { touching = true; setTimeout(() => { if (touching) { evt = e; operation = 'plus'; unitI = unit; start = true; } }, 500) }} onTouchEnd={() => { touching = false; start = false; }} onClick={(evt) => dispatch(setItem(handleButtonClick(evt, unit, 'plus', itemState)))}><img src={modalPlus} alt="" /></button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <button className="modal-add-to-cart-button cart-add-button" onClick={addToCart}>
                    <img src={cart} alt="" />
                    {submitText} ({calculatePrice(itemState)})
                </button>
            </div>
        </div>
    )
}

export default ModalCartItem